import { useSelector } from "react-redux";
import { getCartItems } from "../reducers/cart.reducer";
import { useLocation, useNavigate } from "react-router-dom";

function CartComponent() {
    const navigate = useNavigate();
    const location = useLocation();
    const cart = useSelector(getCartItems);

    const onOpenCheckoutPage = () => {
        navigate('/gio-hang');
    }

    const canShowCart = (): boolean => {
        return location.pathname !== '/gio-hang';
    }

    return (
        cart && canShowCart() && cart.length > 0 ?
            <div onClick={onOpenCheckoutPage} className="cart-indicator">
                <button type="button"><span className="item-count">{cart?.length}</span>Giỏ hàng</button>
            </div>
            :
            <></>
    );
}

export default CartComponent;