import { useEffect, useState } from "react";
import LayoutComponent from "../../components/layout.component"
import ProductCardComponent from "../../components/product.cart";
import productHttpService from "../../http-services/product-http-service";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CategoryModel } from "../../models/category/category.model";
import categoryHttpService from "../../http-services/category-http-service";

const CategoryListComponent = () => {
    const rootApi = process.env.REACT_APP_API_ROOT;
    const navigate = useNavigate();

    const [categories, setCategories] = useState<CategoryModel[]>();
    useEffect(() => {
        const loadCategories = async () => {
            var jsonInfo = await categoryHttpService.getCategories();
            if (jsonInfo.data) {
                setCategories(jsonInfo.data);
            }
        }

        loadCategories();
    }, []);

    useEffect(() => {
        var formContainer = document.querySelector("#category");
        if (formContainer) {
            var xy = formContainer.getBoundingClientRect();
            setTimeout(() => {
                window.scroll({ top: xy.top + 150, left: 0, behavior: 'smooth' });
            }, 500);
        }
    }, [])

    return <LayoutComponent>
        <section className="banner-bottom py-5" id="category">
            <div className="container menu-box">
                <div className="row">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Trang chủ</Link></li>
                            <li className="breadcrumb-item"><Link to={`/danh-muc-san-pham`}>Danh mục sản phẩm</Link></li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="container py-lg-5">
                {
                    categories && categories?.map(item =>
                        <div id={`category-list-${item.id}`} className="col-md-3 category-item-box" onClick={()=> navigate(`/${item.friendlyUrl}`)}>
                            <h3>{item.name}</h3>
                            <img src={`${rootApi}icons/${item.icon}`} />
                        </div>)
                }
            </div>
        </section>
    </LayoutComponent>
}

export default CategoryListComponent;