import React, { ReactNode, useEffect } from "react";
import { Link } from "react-router-dom";
import { ProductItemModel } from "../models/product/item.product";
import BuyButtonComponent from "./buy-now.button";
import { NumericFormat } from "react-number-format";

interface ProductCardProps {
    product: ProductItemModel
}

function ProductCardComponent(props: ProductCardProps) {

    const product = props.product;
    const rootApi = process.env.REACT_APP_API_ROOT;

    const shareProduct = (productItem: ProductItemModel) => {
        window.navigator.share({
            title: productItem.productName,
            text: productItem.productName,
            url: `${process.env.REACT_APP_WEB_ROOT}${productItem.friendlyUrl}`
        });
    }

    return (
        <div className="col-md-4">
            <div className="block product no-border z-depth-2-top z-depth-2--hover">
                <div className="block-image">
                    <Link to={`/${product?.friendlyUrl}#product-container`}>
                        <img src={`${rootApi}images/${product?.images[0].fileName}`} className="img-center" />
                    </Link>
                    {product?.isNewProduct && <span className="product-ribbon product-ribbon-right product-ribbon--style-1 bg-black text-uppercase">New</span>}
                </div>
                <div className="block-body text-center">
                    <h3 className="heading heading-5 strong-600 text-capitalize">
                        <Link className="product-name" to={`/${product?.friendlyUrl}#product-container`}>
                            {product?.productName}
                        </Link>
                    </h3>
                    <p className="product-description">
                        {
                            product && product.discount > 0 &&
                            <>
                                <NumericFormat className="new-price text-danger" value={product?.discount} displayType="text" thousandSeparator="." decimalSeparator="," prefix="" suffix="₫" />
                                <span> </span>
                                <NumericFormat className="old-price" value={product?.price} displayType="text" thousandSeparator="." decimalSeparator="," prefix="" suffix="₫" />
                            </>
                        }

                        {
                            product && product.discount === 0 &&
                            <>
                                <NumericFormat className="new-price" value={product?.price} displayType="text" thousandSeparator="." decimalSeparator="," prefix="" suffix="₫" />
                            </>
                        }
                    </p>

                    <div className="product-buttons mt-4">
                        <div className="row align-items-center">
                            <div className="col-2">
                                <button type="button" className="btn-icon" data-toggle="tooltip" data-placement="top" title="" data-original-title="Favorite">
                                    <i className="fa fa-heart"></i>
                                </button>
                            </div>
                            <div className="col-2">
                                <button onClick={() => shareProduct(props.product)} type="button" className="btn-icon" data-toggle="tooltip" data-placement="top" title="" data-original-title="Compare">
                                    <i className="fa fa-share"></i>
                                </button>
                            </div>
                            <div className="col-8">
                                <BuyButtonComponent id={product?.productId} product={product} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductCardComponent;