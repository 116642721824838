import { useDispatch, useSelector } from "react-redux";
import LayoutComponent from "../../components/layout.component";
import { addCartItem, deleteCartItem, getCartItems } from "../../reducers/cart.reducer";
import { ProductItemModel } from "../../models/product/item.product";
import { NumericFormat } from "react-number-format";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import orderHttpService from "../../http-services/order-http-service";
import JsonClientMessageStatuses from "../../commons/enums/enum.json-client-message";
import { toast } from "react-toastify";
import { CouponViewModel } from "../../models/coupon/coupon-view.model";

function CheckoutComponent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cart = useSelector(getCartItems);
    const rootApi = process.env.REACT_APP_API_ROOT;

    const [couponCode, setCouponCode] = useState<string>('');
    const [couponInfo, setCouponInfo] = useState<CouponViewModel | null>(null);

    const onChangeQuantity = (isPlus: boolean, item: ProductItemModel): void => {
        let product = cart.find(c => c.productId === item.productId);
        if (!product) {
            return;
        }

        let quantity = isPlus ? 1 : -1;
        let newProduct = { ...product, quantity: quantity };

        dispatch(addCartItem(newProduct));
    }

    const onDeleteCart = (item: ProductItemModel) => {
        dispatch(deleteCartItem(item));
    }

    const getTotalPrice = (): number => {
        if (cart) {
            let totalPrice = cart.map(c => {
                let price = (c.discount > 0 ? c.discount : c.price) * c.quantity;
                return price;
            })?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

            if(couponInfo){
                let discount = couponInfo.isPercent ? totalPrice * (couponInfo.value / 100) : couponInfo.value;
                if(couponInfo.maxValue > 0){
                    discount = discount > couponInfo.maxValue ? couponInfo.maxValue : discount;
                }

                let lastPrice = totalPrice - discount;
                return lastPrice < 0 ? 0 : lastPrice;
            }

            return totalPrice;
        }
        return 0;
    }

    const getCouponInfo = async () => {
        if (couponCode && couponCode.length > 0) {
            var result = await orderHttpService.getVoucherInfo(couponCode);
            if (result.status === JsonClientMessageStatuses.Success) {
                setCouponInfo(result.data);
            } else {
                toast.warn(result.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }

    useEffect(() => {
        if (cart && cart.length == 0) {
            navigate('/');
        }
    }, [cart]);

    return (
        <LayoutComponent>
            <div className="cart-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 col-sm-12">
                            <h1 className="text-center">Chi tiết đơn hàng</h1>
                            <div className="cart-details">
                                {
                                    cart && cart.length > 0 &&
                                    cart.map(c =>
                                        <div className="row cart-item-wrapper">
                                            <div className="col-md-3 product-img-box">
                                                <img src={`${rootApi}images/${c.images[0].fileName}`} alt="Product Image" />
                                            </div>

                                            <div className="col-md-9 product-content-box">
                                                <h4>{c.productName}</h4>

                                                <div className="quantity-form">
                                                    <button onClick={() => onChangeQuantity(false, c)} className="quantity-button decrement">-</button>

                                                    <input type="text" className="quantity" value={c.quantity} readOnly />

                                                    <button onClick={() => onChangeQuantity(true, c)} className="quantity-button increment">+</button>

                                                    <button onClick={() => onDeleteCart(c)} className="quantity-button btn-remove-product">✖</button>
                                                </div>

                                                <h5 className="product-item-price">{c.quantity} x {(c.discount > 0 ? c.discount : c.price)} =
                                                    <span> </span>
                                                    <NumericFormat value={(c.discount > 0 ? c.discount : c.price) * c.quantity} displayType="text" thousandSeparator="." decimalSeparator="," prefix="" suffix="₫" />
                                                </h5>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>

                        </div>
                        <div className="col-md-5 col-sm-12">
                            <div className="cart-summary">
                                <div className="form-horizontal">
                                    <div className="form-group">
                                        <label htmlFor="checkout-fullName">Tên của bạn:</label>
                                        <input type="text" className="form-control" id="checkout-fullName" />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="checkout-phoneNumber">Số điện thoại:</label>
                                        <input type="text" className="form-control" id="checkout-phoneNumber" />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="checkout-address">Địa chỉ (không bắt buộc):</label>
                                        <textarea className="form-control" id="checkout-address"></textarea>
                                    </div>
                                </div>

                                <h2>Bạn có mã giảm giá?</h2>
                                <div className="coupon-form">
                                    <input type="text" name="coupon" onChange={e => setCouponCode(e.currentTarget.value)} />
                                    <button onClick={() => getCouponInfo()} type="submit">Áp dụng</button>
                                </div>

                                <p><i>Lưu ý: miễn phí vận chuyển dưới 10km. Phí ngoài khuc vực Tân Lập và Lợi Hà có thể lên đến 20.000₫</i></p>
                                <h6>Tổng mã giảm giá: 0đ</h6>
                                <hr />
                                <h4>
                                    Tổng thanh toán:<span> </span>
                                    <NumericFormat value={getTotalPrice()} displayType="text" thousandSeparator="." decimalSeparator="," prefix="" suffix="₫" />
                                </h4>
                                <hr />
                                <button className="checkout-button">Đặt hàng</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutComponent>
    )
}

export default CheckoutComponent;