import React from 'react';
import logo from './logo.svg';
import './App.scss';
import HomeComponent from './containers/home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import OrderComponent from './containers/order';
import ProductComponent from './containers/product';
import CategoryComponent from './containers/category';
import CategoryListComponent from './containers/category-list';
import CheckoutComponent from './containers/checkout/checkout';

function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<HomeComponent />} />

          <Route path="dat-lich-hen" element={<OrderComponent />} />

          <Route path="danh-muc-san-pham" element={<CategoryListComponent />} />

          <Route path="danh-muc-san-pham/:categoryName/:categoryId" element={<CategoryComponent />} />

          <Route path="danh-muc-san-pham/:categoryName/:categoryId/:productName/:productId" element={<ProductComponent />} />
          
          <Route path="gio-hang" element={<CheckoutComponent />} />
          
        </Routes>
      </Router>
  );
}

export default App;
