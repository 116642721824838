import { CouponViewModel } from "../models/coupon/coupon-view.model";
import JsonClientMessage from "../models/http/json-client-message.order";
import CreateOrderModel from "../models/order/create-order.model";
import { http } from "./http"

const createOrder = async (model: CreateOrderModel): Promise<JsonClientMessage<string>> => {
    return http.post('order/create-order', model);
}

const getVoucherInfo = async (code : string): Promise<JsonClientMessage<CouponViewModel>> =>{
    return http.get(`order/get-voucher-info?code=${code?.trim()}`);
}

const orderHttpService = {
    createOrder,
    getVoucherInfo
};

export default orderHttpService;